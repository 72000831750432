
import React, { Component } from 'react';
import { Link } from "gatsby"

import Layout from "../layouts/en"
import SEO from "../components/seo"

export default class TermsAndConditionsPage extends Component {

  constructor(props, context) {

    super(props, context);

  }

  render() {

    const specific_date = new Date('2022-01-09');
    const current_date = new Date();
    if(current_date.getTime() > specific_date.getTime())
    {
      return (

        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions" />
  
  
        <div className="terms">
          <div className="container container--1000">
            <h1 className="text--center">
              Terms and Conditions</h1>
            <p className="p--sm">
              AYANA Rewards is a frequent patronage program designed to reward guests for staying in AYANA Komodo Waecicu Beach, AYANA Lako Di’a, AYANA Villas Bali, AYANA Segara Bali, AYANA Resort Bali, RIMBA by AYANA Bali, AYANA Midplaza JAKARTA and Delonix Hotel Karawang. The following Terms & Conditions are intended to protect members of AYANA Rewards. Should you require further clarification, please do not hesitate to email our Membership Services at <a href='mailto:info@ayanarewards.com'>info@ayanarewards.com</a> or log on to <a href='ayanarewards.com'>ayanarewards.com</a>.
            </p>
            <h3>
              Membership Eligibility</h3>
            <ol>
              <li>
                All individual guests are eligible for AYANA Rewards membership. AYANA Rewards membership is not transferable. Members must not maintain membership in or earn points on multiple accounts.</li>
              <li>
                Member may reach a higher membership level when their points qualify. Membership level is valid for 1 (one) year.</li>
              <li>
                Members that have collected 5,000 points a year will be upgraded to Gold. Gold members are required to earn at least 1,500 points a year in the next following years to keep their Gold status and benefits. Members that have collected 10,000 points a year will be upgraded to Platinum. Platinum members are required to earn at least 5,000 points a year in the next following years to keep their Platinum status and benefits. Failure of collecting the points stated will result in the downgrade of the membership level.</li>
              <li>
                AYANA Rewards points are valid for ten (10) years or 120 months from the date of issuance.</li>
              <li>
                AYANA Rewards reserves the right to refuse/terminate Membership without a given reason or reject any application if the required information is not supplied accurately and in full.</li>
            </ol>
            <h3>
              Earning Points</h3>
            <ol>
              <li>
                Members will earn 1 (one) AYANA Rewards point for every IDR 10,000 spent at participating hotels, except at DELONIX Hotel Karawang, where members will earn 1 (one) AYANA Rewards point for every IDR 15,000 spent on every eligible charge, before tax and service. Points will be rounded down to the nearest whole number of points.</li>
              <li>
                AYANA Rewards Gold members are qualified to earn 10% bonus points on eligible spend, while AYANA Rewards Platinum members are qualified to earn 25% bonus points on eligible spend.</li>
              <li>
                AYANA Rewards points are only eligible for members that check-in, stay, and pay for their room charges at the eligible rates. Members may earn points for up to five rooms with the same stay dates at a participating property when (1) each room is paid by a member and all charges are settled upon check-out, (2) one room is occupied by the member.</li>
              <li>
                For all hotels, regardless of the rate booked, members who book through any third-party sites (except GDS) are ineligible to receive points for their room. All members may still earn points on all eligible incidentals charged to their room.</li>
              <li>
                Guests will be eligible to earn points after they become AYANA Rewards members. Points will be credited to members’ account up to 7 (seven) working days after check-out.</li>
              <li>
                Eligible charges for points earning:
                  <ul>
                  <li>
                    AYANA Midplaza JAKARTA
                    <ul>
                      <li>
                        Room revenue with most room rates, except for non-applicable rates such as employee rates, commissionable rates, wholesaler rates, free nights awards, room rates billed to a Master Account, crew room rates, cash & points rates, and group rates. Members who are paying a non-applicable room rate may still earn points on eligible incidental charges. Properties are responsible for flagging these rates as non-pointable in their property systems.</li>
                      <li>
                        Additional room charges (exclude minibar)</li>
                      <li>
                        Food &amp; Beverage charges (exclude HonZen and KaDO Restaurant)</li>
                      <li>
                        SPA treatment charges</li>
                    </ul>
                  </li>
                  <li>
                    DELONIX Hotel Karawang
                    <ul>
                      <li>
                        Room revenue with most room rates, except for non-applicable rates such as free nights awards and cash & points rates.</li>
                      <li>
                        Additional room charges</li>
                      <li>
                        Food &amp; Beverage charges (exclude Sakana Restaurant)</li>
                      <li>
                        SPA treatment charges</li>
                      <li>
                        Laundry, dry clean, business center, transportation, loss & breakage charges</li>
                    </ul>
                  </li>
                  <li>
                    AYANA Villas Bali, AYANA Segara Bali, AYANA Resort Bali, RIMBA by AYANA Bali, and AYANA Komodo Waecicu Beach
                    <ul>
                      <li>
                        Room rates through individual direct booking only, with exceptions of special rates.</li>
                      <li>
                        Additional room charges (exclude minibar)</li>
                      <li>
                        Food &amp; Beverage charges (exclude HonZen)</li>
                      <li>
                        SPA treatment charges</li>
                    </ul>
                  </li>
                  <li>
                    AYANA Lako Di'a
                      <ul>
                      <li>
                        Program package charges and overnight stay charter through individual direct booking only, with exceptions of special rates, and any other consumption including alcohol consumption</li>
                    </ul>
                  </li>
                </ul>
                  ** Taxes &amp; service charges, and other incidental charges are not eligible for AYANA Rewards points.</li>
              <li>
                Members may log in to the AYANA Rewards Members&#39; Portal &ndash; ayanarewards.com &ndash; to check their AYANA Rewards point balance and transaction activities or email the AYANA Rewards Membership Services Manager at info@ayanarewards.com to get an update.</li>
              <li>
                Members who are missing credit from their AYANA Rewards member account must notify the AYANA Rewards Membership Services Manager within 30 days of the check-out date. Failure to notify the Membership Services team, will result to non-credit of the relevant AYANA Rewards points. Members can verify their points on 7 working days after they have checked out. Any claims for missing AYANA Rewards points can be emailed to AYANA Rewards Membership Services via email at info@ayanarewards.com.</li>
              <li>
                AYANA Rewards reserves the right to deduct points from a member&#39;s account balance if the points have been erroneously credited in the account.</li>
            </ol>
            <h3>
              Claiming the Reward</h3>
            <ol>
              <li>
                Members may redeem their AYANA Rewards points for rewards at ayanarewards.com under Members&#39; Portal &ndash; Rewards/Redemption section. In addition to Members&#39; user IDs and passwords, AYANA Rewards reserves the right to seek other personal information for verification purposes.</li>
              <li>
                Minimum AYANA Rewards point levels apply to each reward. Please refer to the Rewards Chart of the website for redemption requirements.</li>
              <li>
                It is member&rsquo;s obligation to always refer to Terms and Conditions stated in each reward before claiming the reward.</li>
              <li>
                For night stay rewards, pre-booking is required by sending an email to info@ayanarewards.com stating the preferred stay date. Please note that there might be an additional surcharge applied during high season period.</li>
              <li>
                All rewards are non-refundable and non-cancellable. Member&rsquo;s points will be deducted at time of redemption and will follow the redemption policy.</li>
              <li>
                Rewards will be valid for up to 6 months since the redemption date. Unused rewards cannot be returned for credit to the Members&#39; accounts or exchanged for cash.</li>
              <li>
                AYANA Rewards is not responsible for Redemption Partners&#39; withdrawal from the program or Partners&#39; rewards cancellation, which may affect the rewards offered.</li>
            </ol>
            <h3>
              General Rules</h3>
            <ol>
              <li>
                Whenever there are changes of personal information, it is the member&#39;s responsibility to update their membership record in the AYANA Rewards Members&#39; Portal or inform AYANA Rewards Membership Services in writing.</li>
              <li>
                The safe keep of the Reward Certificates is the sole responsibility of the member. There shall be no replacement of reward certificates.</li>
              <li>
                The AYANA Rewards Membership Services Office reserves the right to correct any Member statements or AYANA Rewards point accruals or balances that are incorrect due to processing errors.</li>
              <li>
                AYANA Rewards reserves the right to amend the rules, restrictions, program benefits, and special offers without notice. AYANA Rewards reserves the right to terminate the AYANA Rewards program at any time.</li>
              <li>
                Earnings of AYANA Rewards points and redemption of rewards are subject to all applicable laws and regulations.</li>
              <li>
                Benefits and rewards received under AYANA Rewards may be subject to taxes. Such taxes are the sole responsibility of the member who is the recipient of the said benefit or reward.</li>
              <li>
                AYANA Rewards makes no warranties or representations, whether expressed or implied, and expressly disclaim any and all liability (including consequential damages) with respect to the quality of goods or services claimed, redeemed and/or enjoyed through the AYANA Rewards. All conditions, warranties or other terms implied by law are excluded to the fullest extent permitted by applicable laws and regulations. In the event any member transfers the use of any Reward Certificate, this Clause shall also apply to the transferee.</li>
              <li>
                The Participating Hotels and/or AYANA Rewards reserve the right to adjust the AYANA Rewards point values as and when necessary to reflect changing market conditions.</li>
              <li>
                Fraud or abuse concerning AYANA Rewards or reward usage is subject to appropriate administrative and/or legal action by the Participating Hotels and/or AYANA Rewards participating partners.</li>
              <li>
                This Program is void where prohibited by law.</li>
              <li>
                AYANA Rewards Loyalty Program is a program operated by the Participating Hotels. Service providers may also have access to members&#39; records.</li>
              <li>
                All interpretations of the Program Terms and Conditions shall be at the sole discretion of AYANA Rewards. In the event of any dispute, the member agrees to submit to the exclusive jurisdiction of the courts of Indonesia.</li>
            </ol>
          </div>
        </div>
        </Layout>
      );
    }
    else
    {
      return (

        <Layout location={this.props.location}>
          <SEO
            title="Terms and Conditions "
            keyword="Terms and Conditions"
            decription="Terms and Conditions" />
  
  
          <div className="terms">
            <div className="container container--1000">
              <h1 className="text--center">
                Terms and Conditions</h1>
              <p className="p--sm">
                AYANA Rewards is a frequent patronage program designed to reward guests for staying in AYANA Komodo Resort, AYANA Lako Di’a, The Villas at AYANA Resort, BALI, AYANA Resort and Spa, BALI, RIMBA Jimbaran BALI, AYANA Midplaza JAKARTA and Delonix Hotel Karawang. The following Terms & Conditions are intended to protect members of AYANA Rewards. Should you require further clarification, please do not hesitate to email our Membership Services at <a href="mailto:info@ayanarewards.com">info@ayanarewards.com</a> or log on to <a href="https://www.ayanarewards.com/">ayanarewards.com.</a>
              </p>
              <h3>
                Membership Eligibility</h3>
              <ol>
                <li>
                  All individual guests are eligible for AYANA Rewards membership. AYANA Rewards membership is not transferable.&nbsp;Members must not maintain membership in or earn points on multiple accounts.</li>
                {/* <li>
                    Membership is valid within two (2) years.&nbsp;Members account will be deactivated and all accumulated points subject to forfeiture if there&rsquo;s no activity along these years.</li> */}
                <li>
                  Member may reach higher membership level when their points qualify. Membership level is valid for one (1 year). Member that have collected 5,000 points a year will be upgraded to Gold and 1,500 points required a year to maintain Gold status.&nbsp;Member that have collected 10,000 points a year will be upgraded to Platinum and 5,000 points required a year to maintain Platinum status. Failure of collecting the points stated will result in downgarde of membership level.</li>
                <li>
                  AYANA Rewards reserves the right to refuse/terminate Membership without a given reason or reject any application if the required information is not supplied accurately and in full.</li>
              </ol>
              <h3>
                Earning Points</h3>
              <ol>
                <li>
                  Member will earn 1 AYANA Rewards points for every IDR 10,000 spent at participating brands for every eligible charge, before tax and service. Delonix Karawang Hotel and Residence will get 1 AYANA Rewards points for every IDR 15,000 spent, before tax and service. Points will be rounded down to the nearest whole number of points.</li>
                <li>
                  AYANA Rewards point is only eligible for Member that check in, stay, and pay for their room charges at the eligible rates.&nbsp;Member may earn points for up to five rooms with the same stay dates at a participating property when (1) each room is paid by Member and all charges are settled at check out (2) one room is occupied by Member.</li>
                <li>
                  AYANA Rewards Points are valid for ten (10) years or 120 months from date of issuance.</li>
                <li>
                  For all hotels, regardless of the rate booked, members who book through any third party sites (except GDS) are ineligible to receive points for their room. All members may still earn points on all eligible incidentals charged to their room.</li>
                <li>
                  Guest will be eligible to earn points after they become AYANA Rewards member. Points will be credited to member&rsquo;s account up to 7 working days after check out.</li>
                <li>
                  Eligible charges:
                    <ul>
                    <li>
                      AYANA Midplaza JAKARTA
                        <ul>
                        <li>
                          Most room rates: the program allows members to earn points on most room rates, with the only exceptions being the non-applicable rates as employee rates, commisionable rates, wholesaler rates, free nights awards, room rate billed to a Master Account, crew room rates, cash &amp; points rates, and group rates. Members who are paying a non-applicable room rate may still earn points on eligible incidental charges. Properties are responsible for flagging these rates as non-pointable in their property systems.</li>
                        <li>
                          Food &amp; Beverage charges (excluding HonZen and KaDO Restaurant)</li>
                        <li>
                          No-show charges, early departure, and cancellation fees</li>
                      </ul>
                    </li>
                    <li>
                      Delonix Hotel Karawang
                        <ul>
                        <li>
                          All charges are eligible (excluding Sakana Restaurant charges)</li>
                      </ul>
                    </li>
                    <li>
                      AYANA Bali Hotels and AYANA Komodo Resort
                        <ul>
                        <li>
                          Room rates through individual direct booking only, with exceptions of special rates</li>
                      </ul>
                    </li>
                    <li>
                      AYANA Lako Di'a
                        <ul>
                        <li>
                          Program package charges and overnight stay charter through individual direct booking only, with exceptions of special rates, and any other consumption including alcohol consumption</li>
                      </ul>
                    </li>
                  </ul>
                    ** Service charges, taxes, and other incidental charges are not eligible for AYANA Rewards points.</li>
                <li>
                  Members may log in to the AYANA Rewards members&#39; portal - ayanarewards.com - to check their AYANA Rewards point balance and transaction activities or email the AYANA Rewards Membership Services Manager at info@ayanarewards.com to get an update.</li>
                <li>
                  Members who are missing credit from their AYANA Rewards member account must notify the AYANA Rewards Membership Services Manager within 30 days of the check-out date. Failure to notify the membership services team, will result to non-credit of the relevant AYANA Rewards points. Members can verify their points one 7 working days after they have checked out. Any claims for missing AYANA Rewards points can be emailed to AYANA Rewards Membership Services via email at info@ayanarewards.com.</li>
                <li>
                  AYANA Rewards reserves the right to deduct points from a member&#39;s account balance if the points have been erroneously credited in the account.</li>
              </ol>
              <h3>
                Claiming the Reward</h3>
              <ol>
                <li>
                  Members may redeem their AYANA Rewards points for rewards at ayanarewards.com under Members&#39; Portal &ndash; Rewards / Redemption section. In addition to Members&#39; user IDs and passwords, AYANA Rewards reserves the right to seek other personal information for verification purposes.</li>
                <li>
                  Minimum AYANA Rewards point levels apply to each reward. Please refer to the Rewards Chart of the website for redemption requirements.</li>
                <li>
                  It is member&rsquo;s obligation to always refer to Term and Conditions stated in each reward before claiming the reward.</li>
                <li>
                  For night stay rewards, pre-booking is required by sending an email to info@ayanarewards.com&nbsp;stating the preferred stay date. Please note that there might be an additional surcharge applied during high season period.</li>
                <li>
                  All rewards are non-refundable and non-cancellable. Member&rsquo;s points will be deducted at time of redemption and will follow the redemption policy.</li>
                <li>
                  Reward will valid for up to 6 months since redeemed date. Unused reward cannot be returned for credit to the Members&#39; accounts or exchanged for cash.</li>
                <li>
                  AYANA Rewards is not responsible for Redemption Partners&#39; withdrawal from the program or Partners&#39; rewards cancellation, which may affect the rewards offered.</li>
              </ol>
              <h3>
                General Rules</h3>
              <ol>
                <li>
                  Whenever there are changes of personal information, it is the Member&#39;s responsibility to update their membership record in the AYANA Rewards Members&#39; Portal or inform AYANA Rewards Membership Services in writing.</li>
                <li>
                  The safe keep of the Reward Certificates is the sole responsibility of the member. There shall be no replacement of reward certificates.</li>
                <li>
                  The AYANA Rewards Membership Services Office reserves the right to correct any Member statements or AYANA Rewards Point accruals or balances that are incorrect due to processing errors.</li>
                <li>
                  AYANA Rewards reserves the right to amend the rules, restrictions, program benefits, and special offers without notice. AYANA Rewards reserves the right to terminate the AYANA Rewards program at any time.</li>
                <li>
                  Earnings of AYANA Rewards Points and redemption of Rewards are subject to all applicable laws and regulations.</li>
                <li>
                  Benefits and Rewards received under AYANA Rewards may be subject to taxes. Such taxes are the sole responsibility of the Member who is the recipient of the said benefit or Reward.</li>
                <li>
                  AYANA Rewards makes no warranties or representations, whether expressed or implied, and expressly disclaim any and all liability (including consequential damages) with respect to the quality of goods or services claimed, redeemed and/or enjoyed through the AYANA Rewards. All conditions, warranties or other terms implied by law are excluded to the fullest extent permitted by applicable laws and regulations. In the event any Member transfers the use of any Reward Certificate, this Clause shall also apply to the transferee.</li>
                <li>
                  The Participating Hotels and/or AYANA Rewards reserve the right to adjust the AYANA Rewards Point values as and when necessary to reflect changing market conditions.</li>
                <li>
                  Fraud or abuse concerning AYANA Rewards or Reward usage is subject to appropriate administrative and/or legal action by the Participating Hotels and/or AYANA Rewards participating partners.</li>
                <li>
                  This Program is void where prohibited by law.</li>
                <li>
                  AYANA Rewards Loyalty Program is a program operated by the Participating Hotels. Service providers may also have access to Members&#39; records.</li>
                <li>
                  All interpretations of the Program Terms and Conditions shall be at the sole discretion of AYANA Rewards. In the event of any dispute, the Member agrees to submit to the exclusive jurisdiction of the courts of Indonesia.</li>
              </ol>
            </div>
          </div>
        </Layout>
      );
    }
  }
}
